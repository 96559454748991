import { Component } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ConfigurationService } from '@com-delta-dcflightreliability/dc-common';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public environment = this.configService.config.environment;
  buildInfo: string = `AWS Application Build 1.54.0 — 17Sep24 [${this.environment}]`;
  sanitizedBuildInfo: SafeHtml;
  constructor(private readonly configService: ConfigurationService, private sanitizer: DomSanitizer) {
    this.sanitizedBuildInfo = this.sanitizer.bypassSecurityTrustHtml(this.buildInfo);
  }
}
